(function ($, DGS) {
  'use strict';

  DGS.OnLoad.getInstance().register(function () {
    var joblist = $('.joblist'),
      joblist_tabs = $('.joblist__tabs button'),
      joblist_menu = $('.joblist__menu'),
      joblist_menu_btn = joblist_menu.find('.joblist__menu-header'),
      joblist_menu_categories = $('.joblist__menu-list button'),
      joblist_cont = $('.joblist__list'),
      joblist_el = $('.joblist__offer'),
      joblist_cat = [],
      joblist_type = '';

    // open category list on mobile
    joblist_menu_btn.on('click', function (event) {
      event.preventDefault();
      if (joblist_menu.hasClass('is-open')) {
        joblist_menu.removeClass('is-open');
      } else {
        joblist_menu.addClass('is-open');
      }
    });

    // Change tabs of specs
    joblist_tabs.on('click', function (event) {
      event.preventDefault();
      var ths = $(this);
      joblist_tabs.filter('.is-active').removeClass('is-active');
      ths.addClass('is-active');
      joblist_type = ths.attr('data-type');
      show_el(false, 'type');
      change_introBanner(ths);
    });

    // Change categories
    joblist_menu_categories.on('click', function (event) {
      event.preventDefault();
      var ths = $(this);
      if (ths.attr('data-category') == "") {
        joblist_menu_categories.filter('.is-active').removeClass('is-active');
        ths.addClass('is-active');
        joblist_cat = [];
      } else {
        joblist_menu_categories.filter('[data-category=""]').removeClass('is-active');
        if (ths.hasClass('is-active')) {
          ths.removeClass('is-active');
          joblist_cat.splice(joblist_cat.indexOf(ths.attr('data-category')), 1);
        } else {
          ths.addClass('is-active');
          joblist_cat.push(ths.attr('data-category'));
        }
      }
      show_el(false, 'category');
    });
    if (joblist.length > 0) {
      // Update selected categories and types (tabs button) based on queryString
      var type_params = findGetParameter('type'),
        cat_params = findGetParameter('category');
      if (type_params) {
        joblist_type = type_params;
      } else {
        joblist_type = '';
      }
      if (cat_params) joblist_cat = cat_params.split(',');
      //console.log('type_params', joblist_type)
      //console.log('cat_params', joblist_cat)
      // Set selected categories
      joblist_menu_categories.removeClass('is-active');
      if (joblist_cat.length > 0) {
        for (var i = 0; i < joblist_cat.length; i++) {
          joblist_menu_categories.filter('[data-category="' + joblist_cat[i] + '"]').addClass('is-active');
        }
      } else {
        joblist_menu_categories.find('[data-category=""]').addClass('is-active');
      }

      // Set selected types (tabs button)
      joblist_tabs.removeClass('is-active');
      var selected_tab = joblist_tabs.filter('[data-type="' + joblist_type + '"]');
      selected_tab.addClass('is-active');

      // Update intro banner
      change_introBanner(selected_tab);

      // Show elements
      show_el(true, 'all');
    }

    // Methods
    // Update elements to display
    function show_el(firstLoad, queryType) {
      joblist_el.addClass('is-hidden');
      if (joblist_type != '') {
        joblist_el.each(function () {
          var dataType = $(this).data('type').toString();
          var dataTypeList = dataType.split('|');
          if ($(this).hasClass('is-hidden')) {
            if (dataTypeList.indexOf(joblist_type) > -1) {
              $(this).removeClass('is-hidden');
            }
          }
        });
      } else {
        joblist_el.removeClass('is-hidden');
      }
      if (joblist_cat.length > 0) {
        $('.joblist__offer:not(.is-hidden)').each(function () {
          var dataCategory = $(this).data('category').toString();
          var dataCategoryList = dataCategory.split('|');
          $(this).addClass('is-hidden');
          for (var i = 0; i < joblist_cat.length; i++) {
            for (var j = 0; j < dataCategoryList.length; j++) {
              if (joblist_cat[i] === dataCategoryList[j]) {
                $(this).removeClass('is-hidden');
                break;
              }
            }
          }
        });
      }
      if (!firstLoad) {
        if (queryType === 'type') {
          updateQueryStringParam('type', joblist_type);
        } else if (queryType === 'category') {
          updateQueryStringParam('category', joblist_cat);
        } else if (queryType === 'all') {
          updateQueryStringParam('type', joblist_type);
          updateQueryStringParam('category', joblist_cat);
        }
      }
    }

    // Methods for queryString data
    function updateQueryStringParam(key, value) {
      var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
        urlQueryString = document.location.search,
        newParam = key + '=' + value,
        params = '?' + newParam;
      console.log('QueryString: ', urlQueryString);
      console.log('QueryString: ', key, value);

      // If the "search" string exists, then build params from it
      if (urlQueryString) {
        var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
        var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');
        if (typeof value == 'undefined' || value == null || value == '' || value.length === 0) {
          // Remove param if value is empty
          console.log('Remove param if value is empty');
          params = urlQueryString.replace(removeRegex, "$1");
          params = params.replace(/[&;]$/, "");
        } else if (urlQueryString.match(updateRegex) !== null) {
          // If param exists already, update it
          console.log('If param exists already, update it');
          params = urlQueryString.replace(updateRegex, "$1" + newParam);
        } else {
          // Otherwise, add it to end of query string
          console.log('Otherwise, add it to end of query string');
          params = urlQueryString + '&' + newParam;
        }
      }
      console.log('params: ', params);

      // no parameter was set so we don't need the question mark
      params = params == '?' ? '' : params;
      window.history.replaceState({}, "", baseUrl + params);
    }
    function findGetParameter(parameterName) {
      var result = null,
        tmp = [];
      var items = location.search.substr(1).split("&");
      for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      }
      return result;
    }

    //change introbanner
    function change_introBanner(tab_el) {
      var intro_banner = $('.intro-banner.joboffer-banner .intro-banner-image'),
        intro_banner_content = $('.intro-banner.joboffer-banner .intro-banner-content');
      if (intro_banner) {
        if (intro_banner.find('img').length <= 0) intro_banner.html('<img />');
        if ($(tab_el).attr('data-img')) intro_banner.find('img').attr('src', $(tab_el).attr('data-img'));
        if ($(tab_el).attr('data-text')) intro_banner_content.find('.intro-banner-text').empty().append('<h1>' + $(tab_el).attr('data-text') + '</h1>');
      }
    }
  });
})(jQuery, window.DGS);