(function ($) {
  var $window = $(window);
  function setStyleContentOverflow(windowWidth) {
    var $jsIntroBannerSliderNavArrows = $('.js-intro-banner-slider .slick-arrow');

    // If some element has class, set class to all items
    if (windowWidth < 768) {
      console.log('itemSlideHasClass');
      var imageHeight = $('.component.intro-banner.intro-banner-slider .intro-banner-image').height();
      var btnSlickNavHeight = $jsIntroBannerSliderNavArrows.height();
      $jsIntroBannerSliderNavArrows.css('top', imageHeight / 2 - btnSlickNavHeight / 2);
    } else {
      $jsIntroBannerSliderNavArrows.css('top', '');
    }
  }
  function modeDisplaySlider(windowWidth) {
    $('.component.intro-banner.intro-banner-slider').each(function () {
      if (windowWidth > 767) {
        $(this).removeClass('content-overflow');
      }
    });
    setStyleContentOverflow(windowWidth);
  }
  window.DGS.OnLoad.getInstance().register(function () {
    $('#wrapper .intro-banner.intro-banner-slider').wrapAll('<div class="js-intro-banner-slider"></div>');
    // init slick
    $('.js-intro-banner-slider').slick({
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Prev" type="button"></button>',
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>'
    });
    $('.intro-banner.hp-video-banner').each(function () {
      var $this = $(this);
      var video = $this.find('.intro-banner-video'); //.get(0);

      video.trigger('play');
      //video.play();

      $this.find('button.btn-goto').on('click', function (e) {
        e.preventDefault();
        var _th = $(this);
        var gotoClassName = _th.data('goto-class');
        $('html, body').animate({
          scrollTop: $(document).find("." + gotoClassName).offset().top - 60
        }, 300);
      });
    });
    modeDisplaySlider($window.width());
    $window.on('resize', function () {
      // Resize
      modeDisplaySlider($(this).width());
    });
  });
})(jQuery);