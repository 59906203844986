require('./list.js');
require('./selector.js');
(function ($, DGS) {
  'use strict';

  var $window = $(window);
  var hash = location.href.indexOf("#") !== -1 ? true : false;

  // function initSlick($slickParnet, slickSettings) {
  //     console.log('a');
  //     $slickParnet.not('.slick-initialized').each(function (i, el) {
  //         $(el).slick(slickSettings);
  //     });
  // }

  // Go to based on className from hash url
  // init of this function is added twice because it was a problem on scroll on iPhone
  function goToSectionByHash(url) {
    var paramHash = url.split('#')[1];
    if (paramHash.match(/^[^=?]+$/g)) {
      paramHash = '.' + paramHash;
      paramHash = $(paramHash);
      if (paramHash.length > 0) {
        var scrollTopVal = parseInt($(paramHash[0]).offset().top - $('#header').height());
        return scrollTopVal;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  // DGS.OnLoad.getInstance().register(function () {

  //     var $slck = $('.joboffer-benefits__container');

  //     if ($slck.length > 0) {
  //         var slck_settings = {
  //             mobileFirst: true,
  //             arrows: true,
  //             dots: false,
  //             slidesToShow: 2,
  //             responsive: [
  //                 {
  //                     breakpoint: 991,
  //                     settings: 'unslick'
  //                 },
  //                 {
  //                     breakpoint: 768,
  //                     settings: {
  //                         slidesToShow: 3,
  //                         unslick: false
  //                     }
  //                 }
  //             ]
  //         };

  //         initSlick($slck, slck_settings);

  //         $window.on('resize', function () {
  //             initSlick($slck, slck_settings);
  //         });
  //     }

  // });

  DGS.OnContentLoaded.getInstance().register(function () {
    // Go to based on className from hash url
    if (hash) {
      var url = window.location.href;
      setTimeout(window.scrollTo(0, goToSectionByHash(url)), 500);
    }
  });
  $window.on('load', function () {
    // Go to based on className from hash url
    if (hash) {
      var url = window.location.href;
      setTimeout(window.scrollTo(0, goToSectionByHash(url)), 100);
    }
  });
})(jQuery, window.DGS);