(function ($) {
  'use strict';

  var CLASS_IS_OPEN = 'is-opened';
  window.DGS.OnLoad.getInstance().register(function () {
    var btn_gmap = $('#footer .button-google-map');
    btn_gmap.on('click', function (e) {
      e.preventDefault();
      var component = $(this).closest('.component-google-map');
      if (component.hasClass(CLASS_IS_OPEN)) {
        component.removeClass(CLASS_IS_OPEN);
      } else {
        component.addClass(CLASS_IS_OPEN);
        setTimeout(function () {
          $('html, body').animate({
            scrollTop: document.body.scrollHeight || document.documentElement.scrollHeight
          }, 300);
        }, 450);
      }
    });
  });
})(jQuery);