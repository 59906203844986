/**
 * Created by mady on 21-06-2017.
 */
// require("../../../corporate-template/js/feature/text-image-spot");

(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    $('#content .component.text-image-spot.text-with-readmore').each(function () {
      var $context = $(this);
      var $target = $('.text-image-spot-text', $context);
      var $btnReadMore = $('.text-image-spot-text', $context).find('button');
      $btnReadMore.parent().nextAll().hide();
      $btnReadMore.on('click', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
        var $this = $(this);
        var $thisParent = $this.parent();
        var dataTxtLess = $this.data('txt-less');
        var dataTxtMore = $this.data('txt-more');
        if ($thisParent.hasClass('is-active')) {
          // text show more
          $thisParent.removeClass('is-active');
          $this.text(dataTxtMore);
          $thisParent.nextAll().hide();
          $([document.documentElement, document.body]).animate({
            scrollTop: $target.offset().top
          }, 20);
        } else {
          // text show less
          $thisParent.addClass('is-active');
          $this.text(dataTxtLess);
          $thisParent.nextAll().show();
        }
      });
    });
  });
})(jQuery, window.DGS);