require('core-js');require('regenerator-runtime/runtime');// webpack
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('@demant/megamenu');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('@demant/legacy/googlemaps');
require('iframe-resizer');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('jquery-ui-dist/jquery-ui.min.js');
require('lightgallery/src/js/lightgallery.js');
require('slick-carousel/slick/slick.min.js');
require('@demant/legacy/googlemaps');
require('../../corporate-template/js/feature/center-list-spot.js');
require('../../corporate-template/js/feature/center-locator.js');
require('../../corporate-template/js/feature/center-spot.js');
require('../../corporate-template/js/feature/data-hierarchy-spot/component-a-data-hierarchy.js');
require('../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-appframe-component.js');
require('../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-hubspot.js');
require('../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-multi-step.js');
require('../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-resource-center.js');
require('../../corporate-template/js/feature/data-hierarchy-spot/component-data-hierarchy-dataset.js');
require('../../corporate-template/js/feature/data-hierarchy-spot/component-data-hierarchy-spot-ui-elements.js');

// require('../../corporate-template/js/feature/cookies.js');
require('../../corporate-template/js/feature/form.js');
require('../../corporate-template/js/feature/iframe.js');
require('../../corporate-template/js/feature/image-spot.js');
require('../../corporate-template/js/feature/rich-text.js');
require('../../corporate-template/js/feature/submenu-point-spot.js');
require('./feature/component-image-spot.js');
require('./feature/component-intro-banner/index');
require('./feature/component-intro-banner.js');
require('./feature/component-joboffer/index');
require('./feature/component-text-image-spot.js');
require('./feature/component-text-image-spot-readmore.js');
require('./feature/component-blog/index');
require('./feature/component-video-spot.js');
require('./feature/component-site-notification-spot.js');
require('./feature/component-search.js');
require('./feature/search-base.js');
require('../../corporate-template/js/project/main.js');
require('./project/custom-google-map.js');
require('./project/header.js');
require('./project/custom-navigation.js');
require('./project/custom-main.js');
require('@demant/wdh-gdpr/lib/default.implementation.js');