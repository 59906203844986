require('./categories.js');
(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.blog').each(function () {
      // Move the image BEFORE the blog-categories component
      var $blogImage = $('.blog-image', $(this)).insertBefore($('.blog-categories'));
      $blogImage.wrap("<div class='component component-ready blog-custom-no-padding'><div class='component-content'></div></div>");
      $blogImage.css('font-size', 0); // This is to remove the "magic" space after the closing divs
    });
    $('.component.blog-author').each(function () {
      $(".blog-author-about", $(this)).insertAfter($(".blog-author-image", $(this)));
    });

    // Remove the related blogs if there are none
    $('.component.related-blogs').each(function () {
      var $lines = $("ul li", $(this));
      if ($lines.length === 0) {
        $(this).remove();
      }
    });
  });
})(jQuery);