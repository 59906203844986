(function ($, DGS) {
  'use strict';

  var PL_TXT_MENU_SHOW = 'Wybierz inny dział',
    PL_TXT_MENU_HIDE = 'Zamknij',
    EN_TXT_MENU_SHOW = 'Select department',
    EN_TXT_MENU_HIDE = 'Close',
    TXT_MENU = {
      SHOW: '',
      HIDE: ''
    },
    CLASS_IS_ACTIVE = 'is-active',
    CLASS_IS_OPEN = 'is-open';
  function langIsEnglish(currentURL) {
    currentURL = currentURL.substring(0, 3);
    return currentURL.indexOf('/en') >= 0 ? true : false;
  }
  DGS.OnLoad.getInstance().register(function () {
    var $document = $(document),
      $window = $(window),
      currentURL = window.location.pathname;
    if (langIsEnglish(currentURL)) {
      TXT_MENU.SHOW = EN_TXT_MENU_SHOW;
      TXT_MENU.HIDE = EN_TXT_MENU_HIDE;
    } else {
      TXT_MENU.SHOW = PL_TXT_MENU_SHOW;
      TXT_MENU.HIDE = PL_TXT_MENU_HIDE;
    }
    $('#content .component.navigation.submenu-list-links').each(function () {
      var $context = $(this),
        $btnOpenMobileMenu = $context.find('.mobile-nav-inner');
      $btnOpenMobileMenu.text(TXT_MENU.SHOW).on('click', function () {
        var $contextBtn = $(this);
        var $contextParent = $contextBtn.parent();
        var $contextParentFindUl = $contextParent.find('+ ul');
        if ($contextBtn.hasClass(CLASS_IS_OPEN) && $contextParentFindUl.hasClass(CLASS_IS_ACTIVE)) {
          $contextBtn.removeClass(CLASS_IS_OPEN);
          $contextParentFindUl.removeClass(CLASS_IS_ACTIVE);
          $contextBtn.text(TXT_MENU.SHOW);
        } else {
          $contextBtn.addClass(CLASS_IS_OPEN);
          $contextParentFindUl.addClass(CLASS_IS_ACTIVE);
          $contextBtn.text(TXT_MENU.HIDE);
        }
      });
      $('a', $context).each(function () {
        var $this = $(this);
        var getUrl = $this.attr('href');
        if (currentURL == getUrl) {
          $this.addClass(CLASS_IS_ACTIVE);
          return false;
        }
      });
    });

    //scroll animation from #header only for IE
    if ($("#wrapper").hasClass("ie")) {
      $('#header .oneline .action-menu a').each(function () {
        $(this).on('click', function (e) {
          e.preventDefault();
          var headerBarHeight = 55;
          var a = e.target;
          var $target = document.querySelector('.' + a.hash.substr(1));
          if ($target) {
            var targetTop = Math.round($target.getBoundingClientRect().top);
            targetTop < 0 ? headerBarHeight = 111 : headerBarHeight = 70;
            targetTop = targetTop - headerBarHeight;
            $('html, body').animate({
              scrollTop: $(document).find("." + a.hash.substr(1)).offset().top - headerBarHeight
            }, 300);
          }
        });
      });
    }
  });
})(jQuery, window.DGS);