(function ($, DGS) {
  'use strict';

  DGS.OnLoad.getInstance().register(function () {
    var joboffer_controls = $('.joboffer-select__controls'),
      joboffer_select = $('.joboffer-select__controls select'),
      joboffer_btn = $('.joboffer-select__controls button'),
      joboffer_href = joboffer_btn.attr('data-joboffer-href');
    joboffer_select.niceSelect();
    joboffer_btn.on('click', function (event) {
      event.preventDefault();
      window.location.href = joboffer_href + '?category=' + joboffer_select.val();
    });
    $(document).on('click', '.nice-select > .list .option', function (event) {
      event.preventDefault();
      var selectedValue = $(event.currentTarget).data('value');
      joboffer_select.val(selectedValue);
    });
  });
})(jQuery, window.DGS);