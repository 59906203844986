// OVERRIDING THE PLUGIN METHOD slick slider

//Slick.prototype.cleanUpRows = function () {
//    var _ = this, originalSlides;
//    console.log('Slick: Overidden method called cleanUpRows');
//    if (_.options.rows > 0) {
//        originalSlides = _.$slides.children().children().clone(true);
//        originalSlides.removeAttr('style');
//        _.$slider.empty().append(originalSlides);
//    }

//    //this.cleanUpRows.apply(this, arguments);
//};

(function ($) {
  'use strict';

  var $window = $(window);
  function setPosNavBtnsOfSlider() {
    var $navArrows = $('.image-spot.slider-with-btn-video .slick-arrow');
    var imageHeight = $('.image-spot.slider-with-btn-video .image-spot-element-image:eq(0)').height();
    var btnSlickNavHeight = $navArrows.height();
    $navArrows.css('top', imageHeight / 2 - btnSlickNavHeight / 2);
  }
  function initSlickCheckByWidth(removeSlick, $elementOnInitSlick, width, slickSettings) {
    // remove slider
    if (removeSlick === "desktop") {
      if (width.current <= width.conditionalStatement) {
        if ($elementOnInitSlick.hasClass('slick-initialized')) {
          $elementOnInitSlick.slick('unslick');
        }
        return;
      }
    } else if (removeSlick === "mobile") {
      if (width.current >= width.conditionalStatement) {
        if ($elementOnInitSlick.hasClass('slick-initialized')) {
          $elementOnInitSlick.slick('unslick');
        }
        return;
      }
    }

    // init slider
    if (!$elementOnInitSlick.hasClass('slick-initialized')) {
      return $elementOnInitSlick.slick(slickSettings);
    }
  }
  window.DGS.OnContentLoaded.getInstance().register(function () {
    var $sliders = $('.image-spot.carousel-spot-mobile');
    if ($sliders.length > 0) {
      $sliders.find('.image-spot-elements').each(function () {
        var $hp_ispot_slick = $(this);
        var hp_ispot_slick_settings = {
          arrows: false,
          dots: true
        };
        initSlickCheckByWidth('mobile', $hp_ispot_slick, {
          current: $window.width(),
          conditionalStatement: 1024
        }, hp_ispot_slick_settings);
        setTextHeight($hp_ispot_slick);
        $window.on('resize', function () {
          initSlickCheckByWidth('mobile', $hp_ispot_slick, {
            current: $(this).width(),
            conditionalStatement: 1024
          }, hp_ispot_slick_settings);
          setTimeout(function () {
            setTextHeight($hp_ispot_slick);
          }, 200);
        });
      });
    }
    function setTextHeight($this) {
      var textHeight = 0;
      $this.find('.image-spot-element-text').each(function () {
        $(this).css('height', 'auto');
        var elHeight = $(this).height();
        if (elHeight > textHeight) textHeight = elHeight;
      });
      $this.find('.image-spot-element-text').each(function () {
        $(this).css('height', textHeight);
      });
    }

    // ----------------------------------------

    // homepage slick slider (tablet and mobile)
    var wu_ispot_slick = $('.image-spot.carousel-spot-mobile-tablet .image-spot-elements'),
      wu_ispot_slick_settings = {
        mobileFirst: true,
        arrows: false,
        dots: true,
        responsive: [{
          breakpoint: 1024,
          settings: 'unslick'
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            unslick: false
          }
        }]
      };
    if (wu_ispot_slick.length > 0) {
      setPosTopIconPlay();
      $(window).on('resize', function () {
        setPosTopIconPlay();
      });
    }
    function setPosTopIconPlay() {
      var imgHeight = $('.image-spot.carousel-spot-mobile-tablet .image-spot-element-image').height();
      $('.image-spot.carousel-spot-mobile-tablet .image-spot-element-text').css('top', imgHeight / 2);
    }

    // ----------------------------------------

    // why-us slick slider
    var ispot_slick = $('.image-spot.carousel-spot-whyus .image-spot-elements');
    if (ispot_slick.length > 0) {
      ispot_slick.slick({
        dots: true,
        arrows: true,
        mobileFirst: true,
        appendArrows: $('.image-spot.carousel-spot-whyus .component-content')
      });
    }

    // --------------------------------

    // location gallery

    var ispot_gallery = $('.image-spot.with-gallery .image-spot-elements');
    if (ispot_gallery.length > 0) {
      ispot_gallery.lightGallery({
        selector: '.image-spot-element-link a',
        thumbnail: false,
        counter: false,
        zoom: false,
        fullScreen: false,
        download: false
      });
    }
    // --------------------------------

    // faq accordion

    var ispot_accordions = $('.image-spot.accordion .image-spot-element');
    if (ispot_accordions.length > 0) {
      ispot_accordions.on('click', function (e) {
        var ths = $(this);
        if (ths.hasClass('is-active')) {
          ispot_accordions.filter('.is-active').removeClass('is-active');
        } else {
          ispot_accordions.filter('.is-active').removeClass('is-active');
          ths.addClass('is-active');
        }
      });
    }
    // --------------------------------

    // about us slider with btn video 
    var $ispot_sliderWithBtnVideo = $('.image-spot.slider-with-btn-video .image-spot-elements');
    if ($ispot_sliderWithBtnVideo.length > 0) {
      $ispot_sliderWithBtnVideo.slick({
        dots: true,
        arrows: true,
        mobileFirst: true,
        appendArrows: $('.image-spot.slider-with-btn-video .component-content')
      });
      setPosNavBtnsOfSlider($window.width());
      window.DGS.Resizer.getInstance().register(function () {
        // Resize
        setPosNavBtnsOfSlider($(this).width());
      });
    }
    // --------------------------------

    // timeline slider only on mobile
    var $ispot_sliderTimeline = $('.image-spot.like-as-timeline-unnumbered.like-as-timeline-mobile-slider .image-spot-elements');
    if ($ispot_sliderTimeline.length > 0) {
      var pathName = document.location.pathname;
      var ispot_sliderTimeline_settings = {
        dots: true,
        arrows: false
      };
      initSlickCheckByWidth('mobile', $ispot_sliderTimeline, {
        current: $window.width(),
        conditionalStatement: 992
      }, ispot_sliderTimeline_settings);
      $window.on('resize', function () {
        initSlickCheckByWidth('mobile', $ispot_sliderTimeline, {
          current: $(this).width(),
          conditionalStatement: 992
        }, ispot_sliderTimeline_settings);
      });
      if (window.matchMedia('(max-width:992px)').matches) {
        window.onbeforeunload = function () {
          var scrollPosition = $(document).scrollTop();
          sessionStorage.setItem("scrollPosition_" + pathName, scrollPosition.toString());
        };
        if (sessionStorage["scrollPosition_" + pathName]) {
          $("html").animate({
            scrollTop: sessionStorage.getItem("scrollPosition_" + pathName)
          }, 300);
        }
      }
    }
    // --------------------------------

    // list categories icons (our teams)
    var $listCategoriesIcons = $('.image-spot.list-categories-icons');
    if ($listCategoriesIcons.length > 0) {
      $listCategoriesIcons.find('.image-spot-element').each(function () {
        var $this = $(this);
        var anchorUrl = $this.find('.image-spot-element-link > a').attr('href');
        $this.find('.image-spot-element-image > img').wrap("<a href='" + anchorUrl + "'></a>");
      });
    }
    // --------------------------------

    // icons with hovered texts (our teams)
    var $listIconsWithTexts = $('.image-spot.icons-with-hover-txt');
    if ($listIconsWithTexts.length > 0) {
      var removeIsActive = function removeIsActive($container) {
        $container.find('.image-spot-element').each(function () {
          $(this).removeClass('is-active');
        });
      };
      $listIconsWithTexts.find('.image-spot-element').each(function () {
        $(this).find('.image-spot-element-image').on('click touchstart', function (e) {
          e.stopPropagation();
          removeIsActive($listIconsWithTexts);
          if (!$(this).parent().hasClass('is-active')) {
            $(this).parent('.image-spot-element').addClass('is-active');
          }
        });
      });
      $(document).on('click touchstart', function (e) {
        if (!$(e.target).closest('.image-spot-element.is-active').length) {
          removeIsActive($listIconsWithTexts);
        }
      });
    }
  });
})(jQuery);